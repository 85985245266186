.App main {
  text-align: left;
}
.App2 main {
  text-align: center;
}

.ant-layout {
  background-color: white;
}

.nav {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.footer {
    width: 100%;
    background-color: #00FFFF;
    bottom: 0px;
    padding: 5px 0px 5px 0px;
}

.content {
  font-family: 'Roboto', sans-serif;
  font-size: large;
}

.card {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}


.main-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 56px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.etc-logo {
  height: 60px;
}

.sign-in-btn {
  color: #4FA8DC;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
}

.right-btn-group {
  display: flex;
  gap: 1rem;
}

.left-btn-group {
  display: flex;
  gap: 1rem;
}

.action-nav-bar {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  justify-content: space-between;
  background-color: #4FA8DC;
}

.hero-container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 72px;
  margin: 32px 0px;
}

.left-section {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.right-section {
  width: 40%;
}

span.huge-text.tagline {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
}

span.large-text.description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #5A6062;
}


img.fileSearching {
  width: 100%;
}

.value-add-descriptors {
  display: flex;
  gap: 20px;
  border: 1px solid #D9D9D9;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px 48px;
  margin: 56px;
  justify-content: space-between;
}

.descriptor-card {
  display: flex;
  align-items: center;
  gap: 12px;
}

.descriptor-card img {
  width: 40px;
}

.value-add-descriptors .content {
  display: flex;
  flex-direction: column;
  max-width: 100%
}

span.vad-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: black;
  /* margin-bottom: 4px; */
}

span.vad-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(52, 63, 70, 0.5);
}

.feature-list {
  display: flex;
  margin: 24px 56px;
  position: relative;
  align-items: center;
  margin-left: 0px;
}

.list-items-container li {
  padding: 24px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: black;
}

.list-items-container ul {
  width: 80%;
}

.feature-list img {
  width: 40%;
  max-height: 500px;
  z-index: 5;
}

.list-items-gradient {
  position: absolute;
  height: 100%;
  width: 75%;
  right: 0;
  background: linear-gradient(180deg, rgba(79, 168, 220, 0.42) 0%, rgba(79, 168, 220, 0) 100%);
  border-radius: 120px;
}


.site-input-group-wrapper {
  border: 1px solid #000000;
  border-radius: 10px;
  margin: 8px 56px;
  height: 3rem;
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
  padding: 32px 20px;
}

.site-input-group-wrapper .fields-container {
  width: 100%;
}

.fields-container input {
  box-shadow: none;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  color: #263238;
  /* font-size: 25px;
  line-height: 29px; */
}

.fields-container input:disabled {
  opacity: 0.5;
  background: unset;
}

.fields-container input:focus {
  box-shadow: none;
}

.fields-container input:nth-child(1) {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  /* transform: rotate(90deg); */
}

.separation-line {
  border: 1px solid rgba(0, 0, 0, 0.25);
  transform: rotate(90deg);
  height: 80%;
}

.suggestions-area {
  /* border: 1px solid #000000;
  border-radius: 10px; */
  margin: 0px 56px;
  padding: 10px;
  padding: 4px 30px 16px 30px;
}

.suggestions-area .fields-suggestion-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.main-query-suggestion-list {
  display: flex;
  flex-wrap: wrap;
}

.query-suggestion-item {
  padding: 4px 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  /* font-size: 14px; */
  /* line-height: 16px; */

  color: rgba(0, 0, 0, 0.5);
}








.generated-text-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 20px 56px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 600px;
}

.generated-text-result-textarea {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 100%;
}

textarea#generated-text {
  width: calc(100% - 8px);
  height: calc(100% - 16px);
  border: none;
  padding: 20px 16px;
  resize: none;
  margin: 8px 0px 8px 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

textarea#generated-text:focus-visible {
  border: none;
  outline: none;
}

.references-section {
  width: 30%;
  height: 600px;
  overflow: auto;
  background: linear-gradient(150.19deg, rgba(79, 168, 220, 0.15) 0%, rgba(79, 168, 220, 0.0140625) 90.62%, rgba(79, 168, 220, 0) 100%);
}

.references-list-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0px;
}

.refrences-list {
  min-height: 20%;
  max-height: 30%;
  padding: 8px 24px;
  padding-bottom: 0px;
  margin-bottom: 8px;
  border-top: #D9D9D9 1px solid;
  overflow: auto;
}

h1.references-heading {
  text-align: center;
}

a.source-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #70CA7E;
  width: 70%;
}

button.add-citation-button {
  all: unset;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #4FA8DC;
  cursor: pointer;
  width: 30%;
}

.title-row {
  padding: 8px 12px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.reference-body {
  padding: 8px 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.action-nav-bar {
  display: flex; 
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  padding-left: 3.5rem;
  padding-right: 3.5rem; 
  justify-content: space-between; 
  background: linear-gradient(180deg, #4FA8DC 0%, rgba(79, 168, 220, 0.791027) 0%, rgba(79, 168, 220, 0.785023) 99.99%, rgba(79, 168, 220, 0) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 2rem;
}

.action-nav-bar button {
  background: #FFFFFF;
  border: 1px solid #4FA8DC;
  border-radius: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 2px 8px;
  color: #4FA8DC;
  cursor: pointer;
}

.action-nav-bar button.dark-action-button {
  background: #00273E;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  text-align: center;
  color: #FFFFFF;
}


.thin-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: calc(6px / 2);
}

.btn:active:hover, .btn:active:focus {
  animation: none;
  transform: scale(var(--btn-focus-scale,.95));
}

.btn {
  transition-property: color,background-color,border-color,outline-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  animation: button-pop var(--animation-btn,.25s) ease-out;
}

.ant-btn:active {
  color: #4FA8DC;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all .3s;
}

.ant-dropdown-menu {
  height: 400px;
  overflow: auto;
}

.button-dropdown-generate button {
  border-radius: 10px;
  background-color: rgb(79, 168, 220);
  padding: 8px !important;
  
}

.button-dropdown-generate button:hover,
.button-dropdown-generate button:active, 
.button-dropdown-generate button:focus {
  border-radius: 10px;
  background-color: rgb(79, 168, 220);
  padding: 8px !important;
  color: white;
}

.button-dropdown-generate button:first-child:not(:last-child) {
  border-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding-left: 20px !important;
  padding-right:10px !important;
}

.button-dropdown-generate button:last-child:not(:first-child) {
  border-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.button-dropdown-generate button span {
  font-size: 16px;
  font-weight: 600;
}

.button-dropdown-generate button.ant-btn.ant-btn-primary {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu {
  height: auto;
}
.containercen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
